$(document).on('click', '#login-btn', login);
$(document).on('click', '#forgot-password', setTemporalPassword);
$(document).on('click', '.carousel-opt-item', setCarouselIndex);
var carouselList = [];
var carouselIndex = 0;
var carouselInterval = 5000;
var carouselTimer = null;
$(document).ready(function(){
    carouselList.push({
        id: 1
        , unique_id: 'carousel-1'
        , image: '/client/client-login-carousel/bg1.jpg'
        , title: '<p>Creatividad que se traduce en eficiencia.<br> Optimiza la gestión de tu espacio con nuestros productos de papelería y arte.</p><h3>¡Bienvenido a la tienda en línea de Papelería y Arte!</h3>'
    });
    carouselList.push({
        id: 2
        , unique_id: 'carousel-2'
        , image: '/client/client-login-carousel/bg2.jpg'
        , title: '<p>Inspira y gestiona con estilo.<br> Descubre la papelería y arte que transformará tu jardín, colegio o empresa.</p><h3>¡Cotiza, ordena y eleva tu entorno ahora!</h3>'
    });
    carouselList.push({
        id: 3
        , unique_id: 'carousel-3'
        , image: '/client/client-login-carousel/bg3.jpg'
        , title: '<p>Crea, organiza, impacta.<br> Simplifica la gestión de tu negocio con nuestros productos de papelería y arte.</p><h3>¡Descúbrelos hoy!</h3>'
    });
    carouselList.push({
        id: 4
        , unique_id: 'carousel-4'
        , image: '/client/client-login-carousel/bg4.jpg'
        , title: '<p>Crea un entorno eficiente y lleno de inspiración.<br> Descubre cómo nuestros productos pueden transformar tu espacio educativo u oficina.</p><h3>¡Comienza ya!</h3>'
    });
    carouselList.push({
        id: 5
        , unique_id: 'carousel-5'
        , image: '/client/client-login-carousel/bg5.jpg'
        , title: '<p>¡Conoce nuestra extensa variedad de productos de papelería y arte!</p><h3>¡Crea, organiza y triunfa!</h3>'
    });
    setCarouselList();
    carouselTimer = setInterval(function(){
        carouselIndex++;
        if(carouselIndex >= carouselList.length){
            carouselIndex = 0;
        }
        showCarousel();
    }, carouselInterval);
});
function setCarouselList(){
    $('#carousel-opt-list').html('');
    for(var i = 0; i < carouselList.length; i++){
        $('#carousel-opt-list').append('<li class="carousel-opt-item" data-index="'+i+'"></li>');
        /*preload all images in the carousel*/
        var img = new Image();
        img.src = 'images' + carouselList[i].image;
    }
    showCarousel();
}
function showCarousel(){
    var carousel = carouselList[carouselIndex];
    $('#carousel-container').css('background-image', 'url(images' + carousel.image + ')');
    $('#carousel-text-container').html(carousel.title);
    $('#carousel-opt-list .carousel-opt-item').removeClass('active');
    $('#carousel-opt-list .carousel-opt-item[data-index="'+carouselIndex+'"]').addClass('active');
}
function setCarouselIndex(index){
    carouselIndex = $(this).data('index');
    showCarousel();
    clearInterval(carouselTimer);
}
function login(){
    let flag = true;
    let credential = $('#login-credential').val();
    let password = $('#login-password').val();
    if(credential==null || credential == "" || !validateEmail(credential)){
        $('#create-client-name').addClass('is-invalid');
        alertWarning('Debe ingresar el correo electrónico');
        flag = false;
    }else{
        $('#create-client-name').removeClass('is-invalid');
    }
    if(password==null || password == ""){
        $('#create-client-id-type').addClass('is-invalid');
        alertWarning('Debe ingresar la contraseña');
        flag = false;
    }else{
        $('#create-client-id-type').removeClass('is-invalid');
    }
    if(flag){
        $('#login-btn').attr('disabled',true);
        let data = {
            credential:credential,
            password:password
        }
        PostMethodFunction('/client/login',data,null,function(response){
            location.href = '/client/dashboard';
        },function(){
            $('#login-btn').attr('disabled',false);
        });
    }
}
function setTemporalPassword(){
    let flag = true;
    let credential = $('#login-credential').val();
    if(credential==null || credential == "" || !validateEmail(credential)){
        $('#create-client-name').addClass('is-invalid');
        alertWarning('Debe ingresar el correo electrónico');
        flag = false;
    }
    if(flag){
        $('#forgot-password').attr('disabled',true);
        let data = {
            credential:credential
        }
        PostMethodFunction('/client/set-temporal-password',data,null,function(response){
            swallMessage(
                '¡Listo!'
                , 'Se ha enviado una contraseña temporal a tu correo electrónico'
                , 'success'
                , 'Entendido'
                , null
                , null
                , null
                , null
            );
        },function(){
            $('#forgot-password').attr('disabled',false);
        });
    }
}